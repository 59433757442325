.ecommerce-application .content-body {
  position: relative;
}

.ecommerce-application .body-content-overlay {
  z-index: 12 !important;
  position: fixed !important;
}

.ecommerce-application .sidebar-shop {
  z-index: 998;
  width: 260px;
  margin-top: .85rem;
}

.ecommerce-application .sidebar-shop .filter-heading {
  margin-bottom: 1.75rem;
}

.ecommerce-application .sidebar-shop .filter-title {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}

.ecommerce-application .sidebar-shop .price-range li:not(:last-child), .ecommerce-application .sidebar-shop .categories-list li:not(:last-child) {
  margin-bottom: .75rem;
}

.ecommerce-application .sidebar-shop .brand-list li {
  justify-content: space-between;
  align-items: center;
  margin-bottom: .75rem;
  display: flex;
}

.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle .noUi-tooltip {
  opacity: 0;
  transform: translate(-50%, -15%);
}

.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle .noUi-tooltip:before {
  content: "$ ";
}

.ecommerce-application .sidebar-shop .range-slider.noUi-horizontal .noUi-handle:active .noUi-tooltip {
  opacity: 1;
}

.ecommerce-application .sidebar-shop .ratings-list {
  justify-content: space-between;
  margin-bottom: .5rem;
  display: flex;
}

.ecommerce-application .sidebar-shop .ratings-list:last-child {
  margin-bottom: 2.5rem;
}

.ecommerce-application .sidebar-shop .ratings-list ul {
  margin-bottom: 0;
}

.ecommerce-application .sidebar-shop .ratings-list ul .ratings-list-item svg, .ecommerce-application .sidebar-shop .ratings-list ul .ratings-list-item i {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.25rem;
}

.ecommerce-application .filled-star {
  fill: #ff9f43;
  stroke: #ff9f43;
  color: #ff9f43;
}

.ecommerce-application .unfilled-star {
  stroke: #babfc7;
  color: #babfc7;
}

.ecommerce-application .ecommerce-header-items {
  justify-content: space-between;
  display: flex;
}

.ecommerce-application .ecommerce-header-items .result-toggler {
  align-items: center;
  display: flex;
}

.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler {
  padding-left: 0;
}

.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler:active, .ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler:focus {
  outline: 0;
}

.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler .navbar-toggler-icon {
  height: auto;
}

.ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler .navbar-toggler-icon i, .ecommerce-application .ecommerce-header-items .result-toggler .shop-sidebar-toggler .navbar-toggler-icon svg {
  color: #6e6b7b;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
}

.ecommerce-application .ecommerce-header-items .result-toggler .search-results {
  color: #5e5873;
  font-weight: 500;
}

.ecommerce-application .ecommerce-searchbar .input-group {
  border-radius: .3rem;
  box-shadow: 0 2px 8px #22292f24;
}

.ecommerce-application .search-product, .ecommerce-application .input-group-text {
  border: none;
  height: 48px;
  padding-left: 1.25rem;
  font-size: .95rem;
}

.ecommerce-application .search-product::placeholder, .ecommerce-application .input-group-text::placeholder {
  font-size: .95rem;
}

.ecommerce-application .ecommerce-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 25px #22292f40;
}

.ecommerce-application .ecommerce-card .item-rating ul {
  margin-bottom: 0;
}

.ecommerce-application .ecommerce-card .item-rating svg, .ecommerce-application .ecommerce-card .item-rating i {
  width: 1.143rem;
  height: 1.143rem;
  font-size: 1.143rem;
}

.ecommerce-application .ecommerce-card .item-name {
  margin-bottom: 0;
}

.ecommerce-application .ecommerce-card .item-name a {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
}

.ecommerce-application .ecommerce-card .item-description {
  font-size: .875rem;
}

.ecommerce-application .ecommerce-card .btn-wishlist span, .ecommerce-application .ecommerce-card .btn-cart span {
  vertical-align: text-top;
}

.ecommerce-application .ecommerce-card .btn-wishlist i, .ecommerce-application .ecommerce-card .btn-wishlist svg, .ecommerce-application .ecommerce-card .btn-cart i, .ecommerce-application .ecommerce-card .btn-cart svg {
  vertical-align: text-top;
  margin-right: .25rem;
}

.ecommerce-application .ecommerce-card .btn-wishlist i.text-danger, .ecommerce-application .ecommerce-card .btn-wishlist svg.text-danger, .ecommerce-application .ecommerce-card .btn-cart i.text-danger, .ecommerce-application .ecommerce-card .btn-cart svg.text-danger {
  fill: #ea5455;
}

.ecommerce-application .grid-view:not(.wishlist-items), .ecommerce-application .list-view:not(.wishlist-items) {
  margin-top: 2rem;
}

.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  display: grid;
}

.ecommerce-application .grid-view.wishlist-items {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ecommerce-application .grid-view .ecommerce-card {
  overflow: hidden;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  align-items: center;
  min-height: 15.85rem;
  padding-top: .5rem;
  display: flex;
}

.ecommerce-application .grid-view .ecommerce-card .item-wrapper {
  justify-content: space-between;
  align-items: baseline;
  display: flex;
}

.ecommerce-application .grid-view .ecommerce-card .shipping, .ecommerce-application .grid-view .ecommerce-card .item-company, .ecommerce-application .grid-view .ecommerce-card .item-options .item-price {
  display: none;
}

.ecommerce-application .grid-view .ecommerce-card .item-options {
  flex-wrap: wrap;
  display: flex;
}

.ecommerce-application .grid-view .ecommerce-card .item-options .btn-cart, .ecommerce-application .grid-view .ecommerce-card .item-options .btn-wishlist {
  border-radius: 0;
  flex-grow: 1;
}

.ecommerce-application .grid-view .ecommerce-card .item-name {
  margin-top: .75rem;
}

.ecommerce-application .grid-view .ecommerce-card .item-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: .2rem;
  overflow: hidden;
}

.ecommerce-application .grid-view .ecommerce-card .item-price {
  font-weight: 600;
}

.ecommerce-application .grid-view .ecommerce-card .card-body {
  padding: 1rem;
}

.ecommerce-application .list-view {
  grid-template-columns: 1fr;
  display: grid;
}

.ecommerce-application .list-view .ecommerce-card {
  grid-template-columns: 1fr 2fr 1fr;
  display: grid;
  overflow: hidden;
}

.ecommerce-application .list-view .ecommerce-card .item-img {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.ecommerce-application .list-view .ecommerce-card .card-body {
  border-right: 1px solid #ebe9f1;
  flex-direction: column;
  padding: 1.5rem 1rem;
  display: flex;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-wrapper {
  order: 2;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-name {
  order: 1;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-description {
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  order: 3;
  display: -webkit-box;
  overflow: hidden;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-price {
  display: none;
}

.ecommerce-application .list-view .ecommerce-card .card-body .item-rating {
  margin-bottom: .3rem;
}

.ecommerce-application .list-view .ecommerce-card .item-company {
  margin: .3rem 0 .5rem;
  font-size: .875rem;
  font-weight: 400;
  display: inline-flex;
}

.ecommerce-application .list-view .ecommerce-card .item-company .company-name {
  margin-left: .25rem;
  font-weight: 600;
}

.ecommerce-application .list-view .ecommerce-card .item-options {
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  display: flex;
}

.ecommerce-application .list-view .ecommerce-card .item-options .item-wrapper {
  position: relative;
}

.ecommerce-application .list-view .ecommerce-card .item-options .item-wrapper .item-cost .item-price {
  color: #7367f0;
  margin-bottom: 0;
}

.ecommerce-application .list-view .ecommerce-card .item-options .shipping {
  margin-top: .75rem;
}

.ecommerce-application .list-view .ecommerce-card .item-options .btn-wishlist, .ecommerce-application .list-view .ecommerce-card .item-options .btn-cart {
  margin-top: 1rem;
}

.ecommerce-application .checkout-tab-steps .bs-stepper-header, .ecommerce-application .checkout-tab-steps .bs-stepper-content {
  margin: 0;
  padding: 0;
}

.ecommerce-application .checkout-items .ecommerce-card .item-img img {
  width: 200px;
}

.ecommerce-application .checkout-items .ecommerce-card .item-name {
  order: 0 !important;
}

.ecommerce-application .checkout-items .ecommerce-card .item-company, .ecommerce-application .checkout-items .ecommerce-card .item-rating {
  margin-bottom: .4rem !important;
}

.ecommerce-application .checkout-items .ecommerce-card .item-quantity {
  align-items: center;
  display: flex;
}

.ecommerce-application .checkout-items .ecommerce-card .delivery-date {
  margin-top: 1.2rem;
  margin-bottom: .25rem;
}

.ecommerce-application .checkout-items .ecommerce-card .item-options .btn {
  justify-content: center;
  align-items: center;
  display: flex;
}

.ecommerce-application .checkout-options .coupons:focus-within {
  box-shadow: none;
}

.ecommerce-application .checkout-options .coupons input {
  color: #6e6b7b;
  border: none;
  padding-left: 0;
  font-weight: 600;
}

.ecommerce-application .checkout-options .coupons input::placeholder {
  color: #6e6b7b;
  font-weight: 600;
}

.ecommerce-application .checkout-options .coupons .input-group-text {
  padding: inherit;
  height: auto;
  font-weight: 600;
}

.ecommerce-application .checkout-options .price-details .price-title {
  margin-top: 1.5rem;
  margin-bottom: .75rem;
  font-weight: 600;
}

.ecommerce-application .checkout-options .price-details .price-detail {
  justify-content: space-between;
  margin-bottom: .75rem;
  display: flex;
}

.ecommerce-application .checkout-options .price-details .price-detail .detail-title.detail-total {
  font-weight: 600;
}

.ecommerce-application .payment-type .gift-card {
  cursor: pointer;
}

.ecommerce-application .checkout-tab-steps {
  box-shadow: none !important;
  background-color: #0000 !important;
}

.ecommerce-application .checkout-tab-steps .bs-stepper-header {
  border: none;
}

@media (width >= 992px) {
  .ecommerce-application .ecommerce-header-items .shop-sidebar-toggler {
    display: none;
  }

  .ecommerce-application .product-checkout.list-view {
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
  }
}

@media (width <= 1199.98px) {
  .ecommerce-application .ecommerce-header-items .btn-group {
    align-items: center;
  }

  .ecommerce-application .ecommerce-header-items .btn-group .btn-icon {
    padding: .6rem .736rem;
  }

  .ecommerce-application .grid-view.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .ecommerce-application .body-content-overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
  }

  .ecommerce-application .body-content-overlay.show {
    opacity: 1;
  }

  .ecommerce-application.horizontal-layout .body-content-overlay {
    z-index: 998 !important;
  }

  .ecommerce-application.horizontal-layout .sidebar-shop {
    z-index: 999 !important;
  }
}

@media (width <= 991.98px) {
  .ecommerce-application .sidebar-left .sidebar .card {
    border-radius: 0;
    margin: 0;
  }

  .ecommerce-application .sidebar-left .sidebar .sidebar-shop {
    height: 100%;
    margin: 0;
    transition: all .25s;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    transform: translateX(-112%);
  }

  .ecommerce-application .sidebar-left .sidebar .sidebar-shop.show {
    transition: all .25s;
    transform: translateX(0);
  }

  .ecommerce-application .grid-view {
    grid-template-columns: 1fr 1fr;
  }

  .ecommerce-application .ecommerce-header-items .result-toggler .search-results {
    display: none;
  }
}

@media (width <= 767.98px) {
  .ecommerce-application .grid-view.wishlist-items {
    grid-template-columns: 1fr 1fr;
  }

  .ecommerce-application .list-view .ecommerce-card {
    grid-template-columns: 1fr;
  }

  .ecommerce-application .list-view .ecommerce-card .item-img {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .ecommerce-application .list-view .ecommerce-card .card-body {
    border: none;
  }
}

@media (width <= 575.98px) {
  .ecommerce-application .grid-view, .ecommerce-application .grid-view.wishlist-items {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=index.5fa2571b.css.map */
